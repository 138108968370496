<template>
  <div>
    <ConfirmDialog ref="confirm"></ConfirmDialog>
    <ConfirmDialog ref="warning"></ConfirmDialog>

    <NewChartDialog ref="newSourceDialog" v-model="showNewChartDialog" :chart="selectedChart" @create-chart="createChart" @edit-chart="createChart" />
    <SaveDashboardDialog ref="saveDashboardDialog" v-model="showSaveDashboardDialog" @save-dashboard="doSaveLayout" />

    <MainTitle title="Dashboard" icon="mdi-monitor-dashboard" class="pb-0" />

    <v-row>
      <v-col class="pt-0" cols="12" xl="12" lg="12" md="12" sm="12">
        <v-alert class="text-center ml-8 mr-8" :value="this.error" dismissible outlined text type="error" color="red"
          @input="closeAlert">
          {{ this.errorMsg }}
        </v-alert>
        <v-alert class="text-center ml-8 mr-8" :value="this.saveSuccess" dismissible outlined text color="green"
          @input="closeSuccessAlert">
          {{ this.saveSuccessMsg }}
        </v-alert>
      </v-col>
    </v-row>

    <SubTitle title="Charts" icon="mdi-chart-line" class="pb-0" />
    <v-container fluid class="pl-8 pr-8">
      <v-row align="start" justify="space-between">
        <v-col cols="auto">
          <v-select
            :items="layouts"
            label="Dashboards"
            v-model="title"
            @input="onLayoutChanged"
            dense
          ></v-select>
        </v-col>
        <v-col cols="auto">
          <DatePickerRange class="mr-3" @period-updated="onPeriodUpdated" ref="rangePicker"></DatePickerRange>
          <RefreshPeriodSelector class="mr-3" v-model="refreshPeriod"></RefreshPeriodSelector>
          <v-btn class="white--text mr-3" color="logo" @click.stop="addPanel">
            <v-icon left> mdi-plus </v-icon>
            Añadir panel
          </v-btn>
          <v-btn class="white--text mr-3" color="logo" @click.stop="newLayout">
            <v-icon left> mdi-file-outline </v-icon>
            Nuevo
          </v-btn>
          <v-btn class="white--text mr-3" color="logo" @click.stop="saveLayout">
            <v-icon left> mdi-content-save </v-icon>
            Guardar
          </v-btn>
          <v-btn class="white--text" color="red darken-3" :disabled="!title" @click.stop="removeLayout">
            <v-icon left> mdi-delete </v-icon>
            Borrar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="pb-8 pl-8 pr-8">
      <div class="grid-stack">
        <div v-for="w in items" class="grid-stack-item" :gs-x="w.x" :gs-y="w.y" :gs-w="w.w" :gs-h="w.h"
          :gs-id="w.id" :id="w.id" :key="w.id">
          <v-card class="grid-stack-item-content d-flex flex-column justify-center align-center">
            <v-menu :close-on-content-click="true" offset-y>
              <template v-slot:activator="{ on, props }">
                <v-btn icon v-on="on" v-bind="props" style="position: absolute; right: 0; top:0 ;">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list density="compact">
                <v-list-item @click="onEditChart(w.id)">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item @click="onRemoveChart(w.id)">
                  <v-list-item-title>Remove</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-card-title v-if="w.content != null" style="cursor: move">
              {{ w.content.title }}
            </v-card-title>
            <v-card-text class="d-flex flex-column justify-center align-center"
              style="width: 100%; height: calc(100% - 2rem - 32px);">
              <v-btn v-if="w.content == null" class="white--text mb-4 mt-4 align-self-center" color="logo"
                @click.stop="openChartConfig(w.id)">
                <v-icon left> mdi-plus </v-icon>
                Añadir gráfica
              </v-btn>
              <plotly-chart v-else :measurement="w.content.measurement" :fields="w.content.vars"
                :chart-type="w.content.type" :min-value="w.content.minValue" :max-value="w.content.maxValue"
                :start="startDateTime" :stop="endDateTime" :ref="'chart_' + w.id" />
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-container>

  </div>
</template>

<script>
// @ is an alias to /src
import PlotlyChart from '@/components/PlotlyChart.vue';
import MainTitle from "@/components/MainTitle";
import SubTitle from "@/components/SubTitle";
import RefreshPeriodSelector from "@/components/RefreshPeriodSelector";
import DatePickerRange from "@/components/DatePickerRange";
import NewChartDialog from "@/views/Charts/NewChartDialog";
import SaveDashboardDialog from "@/views/Charts/SaveDashboardDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import { saveLayout, getLastLayout, getLayoutTitles, getLayout, removeLayout } from '@/api/metrics';
import { GridStack } from "gridstack";
import "gridstack/dist/gridstack.min.css";

export default {
  name: "datalogger-config-item",
  components: {
    MainTitle,
    SubTitle,
    NewChartDialog,
    ConfirmDialog,
    SaveDashboardDialog,
    PlotlyChart,
    DatePickerRange,
    RefreshPeriodSelector
  },

  data() {
    return {
      showNewRowDialog: false,
      showNewChartDialog: false,
      showSaveDashboardDialog: false,
      startDateTime: null,
      endDateTime: null,
      error: false,
      errorMsg: null,
      saveSuccess: null,
      saveSuccessMsg: null,
      editingIndex: null,
      selectedChart: null,
      grid: undefined,
      items: [],
      refreshPeriod: '5s',
      refreshTimer: null,
      count: 0,
      title: null,
      layouts: ["Mi layout"]
    };
  },
  watch: {
    refreshPeriod() {
      this.resetTimer();
      this.setupTimer();
    }
  },
  beforeDestroy() {
    this.resetTimer();
  },
  mounted() {
    this.grid = GridStack.init({ float: true, cellHeight: '200px', minRow: 1 });
    this.startDateTime = this.$refs.rangePicker.getStartDateTime();
    this.endDateTime = this.$refs.rangePicker.getEndDateTime();
    this.loadLayout();
    this.loadLayoutTitles();
    this.grid.on('resizestop', (event, el) => {
      var item = el.gridstackNode;
      console.log(this.items);
      for (var i = 0; i < this.items.length; i++) {
        if (this.items[i].id == item.id) {
          this.items[i].w = item.w;
          this.items[i].h = item.h;
          this.items[i].x = item.x;
          this.items[i].y = item.y;
          break;
        }
      }
    });
    this.grid.on('change', (event, items) => {
      for (var j = 0; j < items.length; j++) {
        var item = items[j];
        for (var i = 0; i < this.items.length; i++) {
          if (this.items[i].id == item.id) {
            this.items[i].w = item.w;
            this.items[i].h = item.h;
            this.items[i].x = item.x;
            this.items[i].y = item.y;
            break;
          }
        }
      }
    });
    this.setupTimer();
  },
  methods: {
    resetTimer: function () {
      if (this.refreshTimer) {
        clearInterval(this.refreshTimer);
        this.refreshTimer = null;
      }
    },
    setupTimer: function () {
      if (this.refreshPeriod != "off") {
        this.refreshTimer = setInterval(() => {
          this.refresh();
        }, this.getRefreshInterval() * 1000);
      }
    },
    getRefreshInterval: function () {
      var units = this.refreshPeriod.slice(-1);
      var value = parseInt(this.refreshPeriod.slice(0, -1));
      if (units == "m")
        value *= 60;
      else if (units == "h")
        value *= 3600;
      return value;
    },
    addPanel: function () {
      var x = 0, y = 0;
      while (!this.grid.isAreaEmpty(x, y, 2, 2)){
        x++;
        if (x > 10) {
          x = 0;
          y++;
        }
      }
      const node = { x: x, y: y, w: 2, h: 2 };
      console.log(node);
      node.id = 'w_' + (this.count++);
      node.content = null;
      this.items.push(node);
      this.$nextTick(() => {
        this.grid.makeWidget(node.id);
      });
    },
    logItems: function () {
      console.log(this.grid.save(false, false));
    },
    openChartConfig: function (id) {
      this.editingIndex = this.getIndexById(id);
      this.showNewChartDialog = true;
    },
    createChart: function (data) {
      this.items[this.editingIndex].content = data;
    },
    refresh: function () {
      for (var i = 0; i < this.items.length; i++) {
        if (this.$refs['chart_' + this.items[i].id] !== undefined && this.$refs['chart_' + this.items[i].id].length > 0)
          this.$refs['chart_' + this.items[i].id][0].fetchPlotData();
      }
    },
    getIndexById: function(id){
      for (var i = 0; i < this.items.length; i++) {
        if (this.items[i].id == id) {
          return i;
        }
      }
      return -1;
    },
    onEditChart: function(id){
      this.editingIndex = this.getIndexById(id);
      this.selectedChart = this.items[this.editingIndex].content;
      this.showNewChartDialog = true;
    },
    onRemoveChart: function(id){
      var idx = this.getIndexById(id);
      this.items.splice(idx, 1);
    },
    onPeriodUpdated: function (period) {
      console.log(period);
      this.startDateTime = period.from;
      this.endDateTime = period.to;
      this.resetTimer();
      this.$nextTick(() => {
        this.refresh();
      });
      this.setupTimer();
    },
    onLayoutChanged: function() {
      getLayout(this.title)
        .then(response => {
          this.newLayout();
          this.$nextTick(() => {
            this.doLoadLayout(response);
          });
        })
        .catch(error => {
          console.error("Error loading layout:", error);
        });
    },
    loadLayoutTitles: function () {
      getLayoutTitles()
        .then(response => {
          this.layouts = response;
        })
        .catch(error => {
          console.error("Error loading layout:", error);
        });
    },
    doLoadLayout: function(response){
      console.log(response.layout);
      this.count = response.count;
      console.log(response.layout);
      this.items = response.layout;
      this.title = response.title;
      this.startDateTime = response.startDateTime;
      if (this.startDateTime.length == 0)
        this.startDateTime = 'now-5m';
      this.endDateTime = response.endDateTime;
      if (this.endDateTime.length == 0)
        this.endDateTime = 'now';
      this.refreshPeriod = response.refreshPeriod;
      if (this.refreshPeriod.length == 0)
        this.refreshPeriod = '5s';
      this.$refs.rangePicker.setPeriod(this.startDateTime, this.endDateTime);
      this.$nextTick(() => {
        for (var i = 0; i < this.items.length; i++){
          this.grid.makeWidget(this.items[i].id);
        }
      });
    },
    loadLayout: function () {
      getLastLayout()
        .then(response => {
          this.doLoadLayout(response);
        })
        .catch(error => {
          console.error("Error loading layout:", error);
        });
    },
    newLayout: function() {
      this.count = 0;
      this.items = [];
      this.title = null;
      this.grid.load([]);
    },
    saveLayout: function() {
      if (this.title == null)
        this.showSaveDashboardDialog = true;
      else
        this.doSaveLayout(this.title);
    },
    removeLayout: function() {
      this.$refs.confirm
          .open(
            "Confirm",
            `¿Estas seguro de eliminar el dashboard '${this.title}'?`,
            { color: "accent" }
          )
          .then((confirm) => {
            if (confirm) {
              removeLayout(this.title)
                .then(response => {
                  this.title = null;
                  this.saveSuccess = true;
                  this.saveSuccessMsg = response.message;
                  console.log("Layout removed successfully:", response);
                  this.loadLayoutTitles();
                  this.newLayout();
                })
                .catch(error => {
                  console.error("Error removing layout:", error);
                });
            }
          });
    },
    doSaveLayout: function (title) {
      var layout = this.grid.save(false, false);
      console.log(layout);
      for (var i = 0; i < layout.length; i++) {
        for (var j = 0; j < this.items.length; j++) {
          if (layout[i].id == this.items[j].id) {
            this.items[j].w = layout[i].w;
            this.items[j].h = layout[i].h;
            this.items[j].x = layout[i].x;
            this.items[j].y = layout[i].y;
            break;
          }
        }
      }
      saveLayout(this.items, this.count, this.startDateTime, this.endDateTime, this.refreshPeriod, title)
        .then(response => {
          this.title = title;
          this.saveSuccess = true;
          this.saveSuccessMsg = response.message;
          console.log("Layout saved successfully:", response);
          this.loadLayoutTitles();
        })
        .catch(error => {
          console.error("Error saving layout:", error);
        });
    },
    closeAlert() {
      this.error = false;
      this.errorMsg = null;
    },
    closeSuccessAlert() {
      this.saveSuccess = false;
      this.saveSuccessMsg = null;
    },
  },
};
</script>
