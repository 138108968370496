// api.js - JavaScript module to call FastAPI endpoints

const API_URL = '/api/metrics';  // Base URL for the FastAPI server

/**
 * Fetches a plot based on the given parameters for multiple fields.
 * 
 * @param {string} measurement - The measurement name in InfluxDB.
 * @param {string[]} fields - An array of field names in InfluxDB.
 * @param {string} start - Start date in RFC3339 format (e.g., "2023-10-01T00:00:00Z").
 * @param {string} stop - End date in RFC3339 format (e.g., "2023-10-02T00:00:00Z").
 * @returns {Promise<Object>} - A promise resolving to the plot data.
 */
async function getPlot(measurement, fields, chartType, minValue, maxValue, start, stop) {
    if (!Array.isArray(fields) || fields.length === 0) {
        throw new Error("Fields must be a non-empty array.");
    }

    // Constructing the query string with multiple `fields` parameters
    const fieldsParam = fields.map(field => `fields=${encodeURIComponent(field)}`).join('&');
    
    var url = `${API_URL}/plot?measurement=${encodeURIComponent(measurement)}&${fieldsParam}&chart_type=${chartType}&start=${encodeURIComponent(start)}&stop=${encodeURIComponent(stop)}`;
    if (minValue != null)
        url += `&min_value=${minValue}`;
    if (maxValue != null)
        url += `&max_value=${maxValue}`;

    try {
        const response = await fetch(url);
        
        if (!response.ok) {
            throw new Error(`Failed to fetch plot: ${response.statusText}`);
        }

        const data = await response.json();
        return data.plot; // Assuming the API now returns multiple plots
    } catch (error) {
        console.error('Error fetching plot:', error);
        throw error;
    }
}

/**
 * Fetches all fields from a given measurement in InfluxDB.
 * 
 * @param {string} measurement - The measurement name.
 * @returns {Promise<string[]>} - A promise resolving to an array of field names.
 */
async function getFields(measurement) {
    const url = `${API_URL}/fields/${encodeURIComponent(measurement)}`;
    
    try {
        const response = await fetch(url);
        
        if (!response.ok) {
            throw new Error(`Failed to fetch fields: ${response.statusText}`);
        }

        const data = await response.json();
        return data.fields;
    } catch (error) {
        console.error('Error fetching fields:', error);
        throw error;
    }
}

/**
 * Fetches a simple hello world message from the API.
 * 
 * @returns {Promise<string>} - A promise resolving to the hello world message.
 */
async function getHello() {
    const url = `${API_URL}/hello`;
    
    try {
        const response = await fetch(url);
        
        if (!response.ok) {
            throw new Error(`Failed to fetch hello message: ${response.statusText}`);
        }

        const data = await response.json();
        return data.hello;
    } catch (error) {
        console.error('Error fetching hello message:', error);
        throw error;
    }
}

/**
 * Stores a layout in the MongoDB collection.
 * 
 * @param {Object} layout - The layout JSON object to store.
 * @returns {Promise<Object>} - The response from the server.
 */
async function saveLayout(layout, count, startDatetime, endDatetime, refreshPeriod, title) {
    try {
        const response = await fetch(`${API_URL}/layout`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                'layout': layout, 
                'count': count, 
                'startDateTime': startDatetime,
                'endDateTime': endDatetime,
                'refreshPeriod': refreshPeriod,
                'title': title
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to save layout: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error saving layout:", error);
        throw error;
    }
}

/**
 * Retrieves the last stored layout from MongoDB.
 * 
 * @returns {Promise<Object>} - The last saved layout JSON object.
 */
async function getLastLayout() {
    try {
        const response = await fetch(`${API_URL}/layout/latest`);

        if (!response.ok) {
            throw new Error(`Failed to fetch layout: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching layout:", error);
        throw error;
    }
}

/**
 * Retrieves all the available layout titles from MongoDB.
 * 
 * @returns {Promise<Object>} - The last saved layout JSON object.
 */
async function getLayoutTitles() {
    try {
        const response = await fetch(`${API_URL}/layout-name`);

        if (!response.ok) {
            throw new Error(`Failed to fetch layout: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching layout:", error);
        throw error;
    }
}

/**
 * Deletes a layout by its title from MongoDB.
 * 
 * @param {string} title - The title of the layout to delete.
 * @returns {Promise<Object>} - A JSON response from the server.
 */
async function removeLayout(title) {
    try {
        const response = await fetch(`${API_URL}/layout?title=${encodeURIComponent(title)}`, {
            method: "DELETE",
        });

        if (!response.ok) {
            throw new Error(`Failed to delete layout: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error deleting layout:", error);
        throw error;
    }
}

/**
 * Gets a layout by its title from MongoDB.
 * 
 * @param {string} title - The title of the layout to get.
 * @returns {Promise<Object>} - A JSON response from the server.
 */
async function getLayout(title) {
    try {
        const response = await fetch(`${API_URL}/layout?title=${encodeURIComponent(title)}`);

        if (!response.ok) {
            throw new Error(`Failed to get layout: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error deleting layout:", error);
        throw error;
    }
}

/**
 * Fetches all measurements from the InfluxDB API.
 * 
 * @returns {Promise<string[]>} - A list of measurement names.
 */
async function getMeasurements() {
    try {
        const response = await fetch(`${API_URL}/measurements`, {
            method: "GET",
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch measurements: ${response.statusText}`);
        }

        const data = await response.json();
        return data.measurements;
    } catch (error) {
        console.error("Error fetching measurements:", error);
        throw error;
    }
}

export { getPlot, getFields, getHello, saveLayout, getLastLayout, getLayoutTitles, removeLayout, getLayout, getMeasurements };
