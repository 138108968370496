<template>
  <v-dialog v-model="show" max-width="80%">
    <v-card>
      <MainTitle title="Charts - Nuevo" icon="mdi-arrow-decision" class="pb-0" />
      <v-form ref="formSource">
        <v-container fluid class="pr-8 pl-8">
          <v-row>
            <v-col class="pt-0" cols="12" xl="12" lg="12" md="12" sm="12">
              <v-alert class="text-center" :value="this.error" dismissible outlined text type="error" color="red"
                @input="closeAlert">
                {{ this.error_msg }}
              </v-alert>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-combobox v-model="measurement" :items="measurements" label="Select the measurement" @change="onMeasurementChanged">
              </v-combobox>
            </v-col>
            <v-col cols="4">
              <v-combobox v-model="vars" :items="fields" label="Select the variables to plot" multiple>
              </v-combobox>
            </v-col>
            <v-col cols="4">
              <v-select color="logo" ref="type" label="Tipo"
                v-model="chartType" class="mr-2" :items="chartTypes" :menu-props="{ offsetY: true }"
                :rules="[() => !!chartType || 'Campo requerido']"></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-text-field v-model="title" label="Título"
                :rules="[() => !!title || 'Campo requerido']">
              </v-text-field>
            </v-col>
            <v-col cols="3" v-if="chartType=='Gauge'">
              <v-text-field v-model="minValue" label="Valor mínimo"
                :rules="[() => !!minValue || 'Campo requerido']">
              </v-text-field>
            </v-col>
            <v-col cols="3" v-if="chartType=='Gauge'">
              <v-text-field v-model="maxValue" label="Valor máximo"
                :rules="[() => !!maxValue || 'Campo requerido']">
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row class="mb-4">
            <v-col>
              <v-btn class="white--text px-4 mr-4" color="logo" type="button" @click.stop="show = false">
                Cancelar
              </v-btn>

              <v-btn class="white--text px-4 mr-4" color="logo" type="button" @click="onSave"
                :disabled="disabledButton()">
                <v-icon left>mdi-content-save</v-icon>Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import MainTitle from "@/components/MainTitle";
import { getFields, getMeasurements } from "@/api/metrics";

export default {
  name: "new-source-item",

  components: {
    MainTitle,
  },

  data() {
    return {
      vars: [],
      fields: [
      ],
      measurement: null,
      measurements: [
      ],
      chartTypes: [
        "Scatter",
        "Gauge"
      ],
      minValue: null,
      maxValue: null,
      title: null,
      chartType: null,
      error: false,
      error_msg: null,
    };
  },
  props: {
    value: Boolean,
    chart: Object
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    form() {
      return {
        measurement: this.measurement,
        vars: this.vars,
        chartType: this.chartType,
        title: this.title,
        minValue: this.minValue,
        maxValue: this.maxValue
      };
    },
  },
  watch: {
    show(visible) {
      if (visible) {
        if (this.chart) { // If editing, populate the fields
          this.vars = this.chart.vars || [];
          this.chartType = this.chart.type || null;
          this.title = this.chart.title || null;
          this.minValue = this.chart.minValue || null;
          this.maxValue = this.chart.maxValue || null;
        }
      } else {
        this.closeAlert();
        this.$refs.formSource.reset();
      }
    },
  },
  mounted() {
    getMeasurements()
      .then(measurements => {
        this.measurements = measurements;
      })
      .catch(error => {
        console.error('Error al obtener los measurements:', error);
      });
  },
  methods: {
    formValid() {
      if (!this.vars.length || !this.chartType || !this.title) {
        return false;
      }
      if (this.chartType === "Gauge" && (!this.minValue || !this.maxValue)) {
        return false;
      }
      return true;
    },
    disabledButton() {
      return !this.formValid();
    },
    onSave: function () {
      if (!this.formValid()) return;
      this.show = false;
      const payload = {
        'measurement': this.measurement,
        'vars': this.vars, 
        'type': this.chartType, 
        'title': this.title, 
        'minValue': this.minValue, 
        'maxValue': this.maxValue
      };
      if (this.chart)
        this.$emit("edit-chart", payload);
      else
        this.$emit("create-chart", payload);
    },
    onMeasurementChanged: function () {
      getFields(this.measurement)
        .then(response => {
          this.fields = response;
          this.vars = [];
        })
        .catch(error => {
          console.error('Error al obtener los measurements:', error);
        });
    },
    closeAlert() {
      this.error = false;
      this.error_msg = null;
    },
  },
};
</script>