<template>
    <v-dialog v-model="show" max-width="30%">
      <v-card>
        <MainTitle title="Guardar" icon="mdi-arrow-decision" class="pb-0" />
  
        <v-form ref="formTitle">
          <v-container fluid class="pr-8 pl-8">
            <v-row v-if="error">
              <v-col cols="12">
                <v-alert v-model="error" class="text-center" dismissible outlined text type="error" color="red">
                  {{ error_msg }}
                </v-alert>
              </v-col>
            </v-row>
  
            <v-row align="center" justify="center">
              <v-col cols="auto" class="d-flex align-center">
                <span>Título:</span>
              </v-col>
              <v-col cols="auto">
                <v-text-field
                  v-model="title"
                  variant="outlined"
                  hide-details
                  class="pt-0"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
  
          <v-container>
            <v-row class="mb-4">
              <v-col>
                <v-btn class="white--text px-4 mr-4" color="logo" @click.stop="show = false">
                  Cancelar
                </v-btn>
  
                <v-btn class="white--text px-4 mr-4" color="logo" @click="onSave" :disabled="disabledButton()">
                  <v-icon left>mdi-content-save</v-icon>Guardar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import MainTitle from "@/components/MainTitle";
  
  export default {
    name: "save-dashboard",
    components: { MainTitle },
  
    data() {
      return {
        title: "",
        error: false,
        error_msg: null,
      };
    },
    props: {
      value: Boolean
    },
    computed: {
      show: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
          if (this.new_config) {
            this.$emit("create-source", this.new_config);
          }
        },
      },
      form() {
        return { title: this.title };
      },
    },
    watch: {
      show(visible) {
        if (!visible) {
          this.closeAlert();
          this.$refs.formTitle.reset();
        }
      },
    },
    methods: {
      formValid() {
        return Object.values(this.form).every(value => !!value);
      },
      disabledButton() {
        return !this.formValid();
      },
      async onSave() {
        if (!this.formValid()) return;
        this.$emit("save-dashboard", this.title);
        this.show = false;
      },
      closeAlert() {
        this.error = false;
        this.error_msg = null;
      },
    },
  };
  </script>
  