<template>
  <div style="display: inline">
    <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y>
      <template #activator="{ on, attrs }">
        <v-btn v-on="on" color="primary" v-bind="attrs">
          <v-icon left>mdi-refresh</v-icon>
          {{ selectedPeriod }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="(period, index) in refreshPeriods" :key="index" @click="selectPeriod(period)">
          <v-list-item-title>{{ period }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: { // Accept the selected value as a prop
      type: String,
      default: '5s',
    },
  },
  mounted(){
    this.selectedPeriod = this.value;
  },
  watch: {
    value(val){
      this.selectedPeriod = val;
    }
  },
  data() {
    return {
      menu: false, // Controls the dropdown menu visibility
      selectedPeriod: '5s', // Default selected period
      refreshPeriods: ['off', '5s', '10s', '30s', '1m', '5m'], // List of refresh periods
    };
  },
  methods: {
    selectPeriod(period) {
      this.selectedPeriod = period; // Update the selected period
      this.menu = false; // Close the dropdown menu
      this.$emit('input', period);
    },
  },
};
</script>

<style scoped>
/* You can style the dropdown or button as needed */
</style>