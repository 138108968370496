<template>
    <div class="d-flex flex-column justify-center align-center" style="position: relative; overflow: hidden; width: 100%; height: 100%;">
      <!-- Loading Spinner -->
      <v-progress-circular
        v-if="loading"
        :size="50"
        :width="5"
        indeterminate
        color="primary"
      ></v-progress-circular>
  
      <!-- Plotly Chart -->
      <div style="width: 100%; height: 100%" ref="plotlyChart"></div>
    </div>
  </template>
  
  <script>
  import { getPlot } from '@/api/metrics';  // Import the getPlot function to fetch data
  import Plotly from 'plotly.js-dist'
  
  export default {
    data() {
      return {
        loading: true,  // State to track loading status
        plotData: null, // Stores the plot data
        resizeObserver: null,
        resizeTimer: null,
      };
    },
    props: {
      measurement: {
        type: String,
        required: true,
      },
      fields: {
        type: Array,
        required: true,
      },
      chartType: {
        type: String,
        required: true,
      },
      minValue: {
        type: String,
        required: false,
      },
      maxValue: {
        type: String,
        required: false,
      },
      start: {
        type: String,
        required: true,
      },
      stop: {
        type: String,
        required: true,
      },
    },
    mounted() {
      this.fetchPlotData();
      //window.addEventListener("resize", this.resizeChart);
    },
    beforeUnmount() {
      // Remove event listener to avoid memory leaks
      //window.removeEventListener("resize", this.resizeChart);
    },
    methods: {
      async fetchPlotData() {
        try {
          this.loading = true;
          // Fetch plot data from the API
          console.log("fetchPlotData");
          const plotJson = await getPlot(this.measurement, this.fields, this.chartType, this.minValue, this.maxValue, this.start, this.stop);
          
          // Parse and render the Plotly chart
          const plotData = JSON.parse(plotJson);
          this.renderPlot(plotData);
        } catch (error) {
          console.error('Error fetching plot data:', error);
          this.loading = false;
        }
      },
      renderPlot(plotData) {
        // Use Plotly to render the chart in the element with id 'plotly-chart'
        Plotly.newPlot(this.$refs.plotlyChart, plotData.data, plotData.layout);

        this.resizeObserver = new ResizeObserver((entries) => {
            this.resizeChart(entries[0].contentRect.width, entries[0].contentRect.height);
        });
        this.resizeObserver.observe(this.$refs.plotlyChart);
        
        // Once the plot is rendered, stop the loading spinner
        this.loading = false;
      },
      resizeChart(w, h) {
        if (this.$refs.plotlyChart) {
          Plotly.relayout(this.$refs.plotlyChart, {
            width: w,
            height: h
          });
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* You can add custom styles here for your chart component */
  </style>
  