<template>
  <div style="display: inline">
    <!-- Main Popup Menu -->
    <v-menu v-model="menu" :close-on-content-click="false" offset-y>
      <template v-slot:activator="{ on }">
        <!-- Button to Open the Popup -->
        <v-btn color="primary" v-on="on">
          <v-icon left>mdi-calendar</v-icon>
          {{ formattedDateRange }}
        </v-btn>
      </template>

      <v-card class="popup-card">
        <v-container>
          <v-row no-gutters>
            <!-- First Column: Date and Time Selection -->
            <v-col cols="12" sm="6" class="pr-5">
              <!-- From Date/Time -->
              <v-row>

                <h4 class="text-left">Rango de fechas absoluto</h4>

              </v-row>
              <DatePickerInput ref="from" label="Desde" :time="true" v-model="startDateTime" default-time="00:00:00">
              </DatePickerInput>
              <DatePickerInput ref="to" label="Hasta" :time="true" v-model="endDateTime" default-time="23:59:59">
              </DatePickerInput>
            </v-col>

            <!-- Second Column: Relative Time Options -->
            <v-col cols="12" sm="6">
              <v-list dense style="max-height: 300px; overflow-y: auto;">
                <v-list-item-group v-model="selectedRelativePeriod">
                  <v-list-item v-for="option in relativeOptions" :key="option.value" @click="applyRelativeTime(option)">
                    <v-list-item-content>
                      <v-list-item-title>{{ option.label }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>

          <!-- Action Buttons -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="menu = false">Cancel</v-btn>
            <v-btn color="primary" text @click="applyDateRange">Apply</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import DatePickerInput from "@/components/DatePickerInput";

export default {
  components: {
    DatePickerInput
  },
  mounted() {
    console.log(this.$attrs);
  },
  data() {
    return {
      from: null,
      to: null,
      menu: false,
      startDateTime: 'now-5m',
      endDateTime: 'now',
      selectedRelativePeriod: null,

      relativeOptions: [
        { label: "Last 5m", value: "last_5m" },
        { label: "Last 15m", value: "last_15m" },
        { label: "Last 30m", value: "last_30m" },
        { label: "Last 1h", value: "last_1h" },
        { label: "Last 6h", value: "last_6h" },
        { label: "Last 12h", value: "last_12h" },
        { label: "Last 24h", value: "last_24h" },
        { label: "Last 7d", value: "last_7d" },
      ],
    };
  },
  computed: {
    formattedDateRange() {
      return `${this.startDateTime || "Select Date"} - ${this.endDateTime || "Select Date"}`;
    },
  },
  methods: {
    getStartDateTime() {
      return this.startDateTime;
    },
    getEndDateTime() {
      return this.endDateTime;
    },
    setPeriod(start, end){
      this.startDateTime = start;
      this.endDateTime = end;
    },
    updateDateRange() {
      // This method can be used for any extra logic when the date range is updated
      console.log("Updated Date Range:", this.startDateTime, this.endDateTime);
    },
    applyDateRange() {
      this.startDateTime = this.$refs.from.getDate();
      this.endDateTime = this.$refs.to.getDate();
      console.log("Final Date Range:", this.startDateTime, this.endDateTime);
      this.menu = false;
      this.$emit('period-updated', {'from': this.startDateTime, 'to': this.endDateTime});
    },
    applyRelativeTime(option) {
      this.$refs.to.setDate('now');
      this.$refs.from.setDate('now-' + option.label.replace('Last ', ''));
      this.selectedRelativePeriod = option.value;
      this.startDateTime = null;
      this.endDateTime = null;
      console.log("Selected Relative Time:", option.label);
    },
  },
};
</script>

<style scoped>
.popup-card {
  width: 600px;
  padding: 10px;
}

.v-text-field {
  margin-bottom: 10px;
}

.v-btn {
  text-transform: none;
  font-size: 12px;
}

.v-row {
  margin-bottom: 10px;
}

.v-list {
  max-height: 200px;
  overflow-y: auto;
}
</style>
