<template>
  <v-row>
    <v-form
      ref="formDatePicker"
      lazy-validation
      :style="{ width: '100%' }"
      class="pr-2"
    >
      <v-menu
        ref="datePicker"
        v-model="datePicker"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateFormatted"
            :label="label"
            persistent-hint
            prepend-icon="mdi-calendar"
            v-bind="attrs"
            @input="date = parseDate(dateFormatted)"
            clearable
            :rules="[() => validDate || 'Introduzca una fecha válida']"
          >
            <template v-slot:prepend>
              <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          locale="es-ES"
          @input="datePicker = false"
          :min="minDate"
          :max="maxDate"
          :first-day-of-week="1"
        ></v-date-picker>
      </v-menu>
    </v-form>
  </v-row>
</template>

<script>
// @ is an alias to /src

export default {
  name: "date-picker-input-item",
  components: {},
  props: {
    label: String,
    time: {
      type: Boolean,
      default: false
    },
    defaultTime: {
      type: String,
      default: '00:00:00'
    },
    value: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      date: null,
      dateFormatted: null,
      datePicker: false,
      minDate: null,
      maxDate: null,
    };
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
      this.$emit("update-date", val);
    },
    dateFormatted(val){
      this.$emit("input", val);
    },
    value(val){
      this.formattedDate = val;
    }
  },
  mounted: function () {
    this.formattedDate = this.value;
    // this.minDate = this.actualDate();
    // this.$refs.formDatePicker.validate();
  },
  computed: {
    validDate(){
      return this.isValidDate(this.dateFormatted);
    }
  },
  methods: {
    getDate(){
      return this.dateFormatted;
    },
    isValidAbsoluteDate(date){
      const regexAbs = /^\d+\/\d+\/\d{2,4}(?: \d+:\d+(?::\d+)?)?$/;
      return date != null && date.match(regexAbs) != null;
    },
    isValidRelativeDate(date){
      const regexRel = /^now(?:-\d+[dhms])?$/;
      return date != null && date.match(regexRel) != null;
    },
    isValidDate(date){
      return this.isValidAbsoluteDate(date) || this.isValidRelativeDate(date);
    },
    setDate(date){
      const regex = /^now(?:-\d+[dhms])?$/;
      if (date.match(regex))
        this.dateFormatted = date;
      else
        this.date = date;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      
      var time = this.defaultTime;
      if (this.isValidAbsoluteDate(this.dateFormatted)){
        var dateTokens = this.dateFormatted.split(' ');
        if (dateTokens.length > 1){
          time = dateTokens[1];
        }
      }

      if (this.time)
        return `${day}/${month}/${year} ${time}`; 
      else 
        return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      if (this.isValidAbsoluteDate(date)){
        const [day, month, year] = date.split(' ')[0].split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }else
        return this.actualDate();
    },
    actualDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
  },
};
</script>